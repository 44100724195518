import {BbxRequest} from "../../Data/request/BbxRequest";
import BbxRequestReducer from "./BbxRequestReducer";
import {InvoiceSearchRequestDTO} from "../../Data/request/InvoiceSearchRequestDTO";

export function InvoiceSearchRequestReducer(state: BbxRequest<InvoiceSearchRequestDTO>, action: any): BbxRequest<InvoiceSearchRequestDTO> {
    state = BbxRequestReducer(state, action);
    switch (action.type) {


        case 'SET_ID':
            return {...state, searchForm: {...state.searchForm, id: action.payload}};
        case 'SET_INVOICE_REF':
            return {...state, searchForm: {...state.searchForm, invoiceRef: action.payload}};
        case 'SET_DATE_START':
            return {...state, searchForm: {...state.searchForm, dateStart: action.payload}};
        case 'SET_DATE_END':
            return {...state, searchForm: {...state.searchForm, dateEnd: action.payload}};
    }

    return state;
}