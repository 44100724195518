//React and Coreui Imports
import React, {Suspense, useEffect, useState} from "react";
import {CAlert, CButton, CContainer, CSpinner} from "@coreui/react";
import {Navigate, Routes, useLocation} from "react-router-dom";

//Components
import Header from "../../Shared/Components/Header/Header";
import Sidebar from "../../Shared/Components/Navigation/Sidebar";
import Breadcrumb from "../../Shared/Components/Navigation/Breadcrumb";
import {BobexSuppliersRoutes} from "./Sidebar/Routes";
import useMediaWidth from "../../CustomHooks/UseMediaWidth";
import LanguageSwitcher from "../../Shared/Components/Header/LanguageSwitcher";
import {accountService} from "../../Services/Helpers/AccountService";
import {useTemplateUpdate} from "../../Shared/Components/SidebarUpdateContextType/SidebarUpdateContextType";
import {useTranslation} from "react-i18next";
import CIcon from "@coreui/icons-react";
import {cilWarning} from "@coreui/icons";
import {CookieUtils} from "../../utils/CookieUtils";


/*
 * Root component of the supplier app.
 * Determines the layout of the supplier app with navigation and header.
 * @return {React.ReactElement}
 */
export default function SupplierApp(): React.ReactElement {
    const isResponsive = useMediaWidth(576);
    const COOKIE_NAME = "hideMonthlyAlert";
    const {t} = useTranslation();
    const user = accountService.getUser();
    const location = useLocation();
    const {findCappingPerYearMonth, expertRole, accountManager} = useTemplateUpdate();
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        setShowAlert(shouldShowMonthlyAlert());
    }, [findCappingPerYearMonth, expertRole.monthlyMaxLeads]);

    if (user === null) {
        accountService.saveRedirectAfterLogin(location.pathname + location.search);
        return <Navigate to="/login"/>;
    }

    const shouldShowMonthlyAlert = () => {
        const hideAlert = CookieUtils.getCookie(COOKIE_NAME);

        if (expertRole.monthlyMaxLeads === -1) {
            return false;
        }

        return !hideAlert && findCappingPerYearMonth() >= expertRole.monthlyMaxLeads;
    };

    const handleCloseAlert = () => {
        // Calculate the expiration date (last day of the month at 23:59)
        const today = new Date();
        const lastDayOfMonthUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0, 23, 59, 59));
        // Save the cookie with an expiration at the end of the month
        CookieUtils.setCookie(COOKIE_NAME, "true", lastDayOfMonthUTC);
    };

    const translatedText = t("Monthly maximum leads reached")
        .replace("{1}", accountManager.accountManager)
        .replace("{2}", accountManager.accountManagerEmail);


    return (
        <>
            <div className="d-flex w-100">
                {/*<CSidebar*/}
                {/*    visible={true}*/}
                {/*>*/}
                {/*    <CSidebarNav>*/}
                {/*        <CAccordion*/}
                {/*            activeItemKey={1}*/}
                {/*        >*/}
                {/*            <CAccordionItem*/}
                {/*                itemKey={1}*/}
                {/*            >*/}
                {/*                <CAccordionHeader>Accordion Item #2</CAccordionHeader>*/}
                {/*                <CAccordionBody>*/}
                {/*                    <CNavItem*/}
                {/*                        active={true}*/}
                {/*                    >*/}

                {/*                        <>aaaaa</>*/}
                {/*                    </CNavItem>*/}
                {/*                </CAccordionBody>*/}
                {/*            </CAccordionItem>*/}
                {/*        </CAccordion>*/}
                {/*    </CSidebarNav>*/}
                {/*</CSidebar>*/}

                <Sidebar/>
                <CContainer className="bobex-app-content-wrapper">
                    <Header/>
                    <CContainer className="bobex-app-content pt-md-0 p-md-4 p-2 mb-sm-0 mx-auto mx-sm-0">
                        {showAlert && (
                            <CAlert color="danger" className="mt-3" dismissible onClose={handleCloseAlert}>
                                <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
                                {translatedText}
                            </CAlert>
                        )}
                        {isResponsive && <Breadcrumb/>}
                        {!isResponsive &&
                            <div className={"d-flex justify-content-between"}>
                                <Breadcrumb/>
                                <div className={"p-2"}>
                                    <LanguageSwitcher/>
                                </div>
                            </div>
                        }
                        <Suspense fallback={<CSpinner/>}>
                            <Routes>
                                {BobexSuppliersRoutes}
                            </Routes>
                        </Suspense>
                    </CContainer>
                </CContainer>
            </div>
        </>
    );
}