//React and Coreui Imports
import React from 'react';
import {Route} from "react-router-dom";
import ProtectedRoute from "../../../Shared/Components/ProtectedRoute/ProtectedRoute";

//Types
import {Routes} from "../../../Shared/Interfaces/Routes.interface";

//Components
const Statistics = React.lazy(() => import('../Statistics/Statistics'));
const Mailing = React.lazy(() => import('../Mailing/Mailing'));
const Api = React.lazy(() => import('../API/Api'));
const Banners = React.lazy(() => import('../Banners/Banners'));
const LandingPage = React.lazy(() => import('../LandingPage/LandingPage'));
const PricePerLead = React.lazy(() => import('../PricePerLead/PricePerLead'));
const ManageAccount = React.lazy(() => import('../ManageAccount/ManageAccount'));
const LeadOverview = React.lazy(() => import('../LeadOverview/LeadOverview'));
const Invoices = React.lazy(() => import('../Invoices/InvoicesCost'));
const InvoiceReport = React.lazy(() => import('../BillingReport/BillingReport'));
const Dashboard = React.lazy(() => import('../Dashboard/Dashboard'));
const NotFound = React.lazy(() => import("../../../Shared/Components/PageNotFound/PageNotFound"));
const LeadSpam = React.lazy(() => import('../LeadSpam/LeadSpam'));
const manageTeamMember = React.lazy(() => import("../../TeamMember/ManageTeamMember"));

/**
 * BobexRoutesDetails
 * Array of objects containing the routes of the application and their linked components.
 */
export const AffiliateRoutes: Routes[] = [
    {path: 'dashboard', name: 'My dashboard', element: Dashboard},
    {path: 'statistics', name: 'My stats', element: Statistics},
    {path: 'marketing/mailing', name: 'Mailing kits', element: Mailing},
    {path: 'marketing/api-documentation', name: 'Api-documentation', element: Api},
    {path: 'marketing/banners', name: 'Banners', element: Banners},
    {path: 'marketing/landing-pages', name: 'Landing pages', element: LandingPage},
    {path: 'leads/my-leads', name: 'My leads', element:LeadOverview},
    {path: 'invoices/prices-per-leads', name: 'Prices per lead', element: PricePerLead},
    {path: 'invoices/billing-report', name: 'Billing Report', element:InvoiceReport},
    {path: 'invoices/my-invoices', name: 'My invoices', element: Invoices},
    {path: 'account/manage-account', name: 'Manage account', element: ManageAccount},
    {path: 'leads/spam', name: 'Leads spam', element: LeadSpam},
    {path: 'account/team-members', name: 'Team', element: manageTeamMember},
    {path: '*', name: '', element: NotFound},
];

/**
 * BobexRoutes
 * Creates a Route component for each route in BobexRoutesDetails.
 * Used to import all routes in the application.
 */
export const BobexAffiliateRoutes = AffiliateRoutes.map((route, index) => {
    return (
        route.element &&
        <Route key={index} element={<ProtectedRoute/>}>
            <Route key={index} path={route.path} element={(<route.element/>)}/>
        </Route>
    )
});