import {CButton, CForm, CFormInput} from "@coreui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faXmark} from "@fortawesome/free-solid-svg-icons";
import {PartnerSubmissionRequestDTO} from "../../../Data/request/PartnerSubmissionRequestDTO";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {BbxRequest} from "../../../Data/request/BbxRequest";


export default function SupplierSearchHeader(): React.ReactElement {
    const {t} = useTranslation();
    const navigate = useNavigate();


    const [partnerSubmissionRequestDTO, setPartnerSubmissionRequestDTO] = useState<BbxRequest<PartnerSubmissionRequestDTO>>(new BbxRequest<PartnerSubmissionRequestDTO>(PartnerSubmissionRequestDTO));

    function submit(partnerSubmissionRequestDTO: BbxRequest<PartnerSubmissionRequestDTO>) {

        if (partnerSubmissionRequestDTO.data.partnerRfpRequest.id > 0) {
            const newPath = `/supplier/activity/leads/${partnerSubmissionRequestDTO.data.partnerRfpRequest.id}`;
            const currentPath = window.location.pathname;

            if (currentPath.startsWith('/extranet-be/supplier/activity/leads/') && currentPath !== newPath) {
                navigate(newPath, {replace: true});
            } else {
                navigate(newPath, {replace: true});
            }

            setPartnerSubmissionRequestDTO(new BbxRequest<PartnerSubmissionRequestDTO>(PartnerSubmissionRequestDTO));
            return;
        }

        if (partnerSubmissionRequestDTO.searchForm.partnerRfpRequest.clientName !== "") {
            navigate("/supplier/activity/leads/?advanced=true&partnerRfpRequest.clientName=" + partnerSubmissionRequestDTO.searchForm.partnerRfpRequest.clientName, {
                replace: true,
            });
            setPartnerSubmissionRequestDTO(new BbxRequest<PartnerSubmissionRequestDTO>(PartnerSubmissionRequestDTO));
            return;
        }

    }


    return (
        <>
            <div className="search-lead-input-wrapper col-10 d-none d-xl-flex justify-content-start">
                <CForm
                    className="d-flex bg-white rounded-pill"
                    onSubmit={
                        (e) => {
                            e.preventDefault();
                            submit(partnerSubmissionRequestDTO);
                        }
                    }>
                    <CFormInput
                        type="text"
                        placeholder={t("Lead number")}
                        value={partnerSubmissionRequestDTO.data.partnerRfpRequest.id === 0 ? "" : partnerSubmissionRequestDTO.data.partnerRfpRequest.id.toString()}
                        onChange={
                            (e) => {
                                const inputText = e.target.value;
                                const numericValue = inputText.replace(/\D/g, ''); // Remove non-numeric characters
                                setPartnerSubmissionRequestDTO({
                                    ...partnerSubmissionRequestDTO,
                                    data: {
                                        ...partnerSubmissionRequestDTO.data,
                                        partnerRfpRequest: {
                                            ...partnerSubmissionRequestDTO.data.partnerRfpRequest,
                                            id: numericValue === '' ? 0 : parseInt(numericValue)
                                        }
                                    }
                                });
                            }}
                    />
                    <CFormInput
                        type="text"
                        placeholder={t("Lead name")}
                        value={partnerSubmissionRequestDTO.searchForm.partnerRfpRequest.clientName}
                        onChange={
                            (e) => {
                                setPartnerSubmissionRequestDTO({
                                    ...partnerSubmissionRequestDTO,
                                    searchForm: {
                                        ...partnerSubmissionRequestDTO.searchForm,
                                        partnerRfpRequest: {
                                            ...partnerSubmissionRequestDTO.searchForm.partnerRfpRequest,
                                            clientName: e.target.value
                                        }
                                    }
                                });
                            }}
                    />
                    <CButton
                        type="submit"
                        color="secondary"
                        variant="outline"
                        shape="rounded-pill"
                    >
                        <FontAwesomeIcon icon={faSearch}/>
                    </CButton>
                    <CButton
                        color="secondary"
                        variant="outline"
                        shape="rounded-pill"
                        className="my-auto"
                        onClick={(e) => {
                            e.preventDefault();
                            setPartnerSubmissionRequestDTO(new BbxRequest<PartnerSubmissionRequestDTO>(PartnerSubmissionRequestDTO));
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark}/>
                    </CButton>
                </CForm>
            </div>
        </>
    );
}