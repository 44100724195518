import {accountService} from "../Services/Helpers/AccountService";
import {enUS, fr, nl} from "date-fns/locale";
import i18n from "i18next";
import {format} from "date-fns";

function formatLocal(date: Date): string {
    let user = accountService.getUser();
    let local = user?.language;
    local = local?.replace("_", "-");

    return new Date(date).toLocaleDateString(local, {});
}

function formatLocalWithTime(date: Date): string {
    let user = accountService.getUser();
    let local = user?.language;
    local = local?.replace("_", "-");

    return new Date(date).toLocaleString(local, {
        dateStyle: "short",  // This can be 'full', 'long', 'medium', or 'short'
        timeStyle: "short"   // This will add hours and minutes in the user's locale format
    });

    /* To remove comma between date and time (but currenty prefer with comma)
    const datePart = new Date(date).toLocaleDateString(local, { dateStyle: "short" });
    const timePart = new Date(date).toLocaleTimeString(local, { hour: '2-digit', minute: '2-digit' });

    return `${datePart} ${timePart}`;
*/
}

function formatHeaderDate(lang: string): string {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    };

    return date.toLocaleDateString(lang.replace("_", "-"), options);
}

function getMonthAbbreviation(monthNumber: number, lang: string, isShort: boolean): string {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    let abbriviatedMonth: string;

    if (isShort) {
        abbriviatedMonth = date.toLocaleString(lang.replace("_", "-"), {month: 'short'}).toUpperCase();
    } else {
        abbriviatedMonth = date.toLocaleString(lang.replace("_", "-"), {month: 'long'});
    }

    return abbriviatedMonth;
}

function getDateFormatByContext(dateFromTable: string | Date | null | undefined): string {
    if (!dateFromTable) return "N/A"; // Ou tout autre valeur par défaut

    const parsedDate = new Date(dateFromTable);
    if (isNaN(parsedDate.getTime())) return "Invalid Date";

    let user = accountService.getUser();
    return format(parsedDate, user.context === "api-nl" ? 'dd-MM-yyyy' : 'dd/MM/yyyy');
}


const getLocale = () => {
    const languageCode = i18n.language.split("_")[0];
    return locales[languageCode] || enUS;
};

const locales: Record<string, Locale> = {
    fr, // Français
    en: enUS, // Anglais
    nl, // Néerlandais standard
};

function formatForInput(date: Date): string {
    //format YYYY-MM-DD
    date = new Date(date);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let monthString = month.toString();

    if (month < 10) {
        monthString = "0" + monthString;

    }

    let dayString = day.toString();

    if (day < 10) {
        dayString = "0" + dayString;
    }

    return year + "-" + monthString + "-" + dayString;
}

function getOneYearPriorDate(todayDate: Date): Date {
    todayDate.setFullYear(todayDate.getFullYear() - 1);
    return todayDate;
};

function getTwoWeeksPriorDate(todayDate: Date): Date {
    const twoWeeksAgo = new Date(todayDate);
    twoWeeksAgo.setDate(todayDate.getDate() - 14);
    return twoWeeksAgo;
}

function getCurrentYearMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Les mois sont indexés à partir de 0 en JavaScript
    return {year, month};
};


function getTodayDate(): Date {
    const currentDate = new Date();
    return new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(), // Set the day to the current day
        0, // Set hours to 0
        0, // Set minutes to 0
        0 // Set seconds to 0
    );

}

function subtractYears(date: any, years: number) {
    date.setFullYear(date.getFullYear() - years);
    return date;
}

function inBetweenDates(date: Date, startDate: Date, endDate: Date): boolean {
    if (date === null || startDate === null || endDate === null) {
        return false;
    }
    if (typeof startDate === 'string') {
        startDate = new Date(startDate);
    }
    if (typeof endDate === 'string') {
        endDate = new Date(endDate);
    }

    date.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    return date >= startDate && date <= endDate;
}

export const DateUtils = {
    formatLocal: formatLocal,
    formatLocalWithTime: formatLocalWithTime,
    formatForInput: formatForInput,
    getDateYearPrior: getOneYearPriorDate,
    getTwoWeeksPriorDate: getTwoWeeksPriorDate,
    getTodayDate: getTodayDate,
    subtractYears,
    formatHeaderDate,
    getMonthAbbreviation,
    inBetweenDates,
    getCurrentYearMonth,
    getDateFormatByContext
}

