//React and Coreui imports
import React, {useEffect, useReducer, useState} from "react";
import {CButton, CCol, CFormInput, CFormLabel, CRow, CSpinner, CTooltip} from "@coreui/react";
import {useTranslation} from "react-i18next";

//Stylesheets
import "./Invoices.css"

//Components
//Types
//Services
import useMediaWidth from "../../../CustomHooks/UseMediaWidth";

//Datas
import {InvoiceSearchRequestDTO} from "../../../Data/request/InvoiceSearchRequestDTO";
import {InvoiceAcctDTO} from "../../../Data/InvoiceAcctDTO";
import Loader from "../../../Shared/Components/Loader/Loader";
import AccountTabs from "../AccountTabs/AccountTabs";
import InvoicesGeneratedModal from "./InvoicesGeneratedModal";
import {SupplierInvoiceAcctService} from "../../../Services/Supplier/SupplierInvoiceAcctService";
import {Page} from "../../../Data/Page";
import {BbxRequest} from "../../../Data/request/BbxRequest";
import BbxTable from "../../../Shared/Components/Table/BbxTable";
import {NumericFormat} from "react-number-format";
import {BbxToastType} from "../../../CustomHooks/ToastCustomComponentProps/BbxToastType";
import {VatUtil} from "../../../utils/VatUtil";
import OgoneForm from "./OgoneForm";
import {SupplierRequestService} from "../../../Services/Supplier/SupplierRequestService";
import {MemberView} from "../../../Data/view/MemberModel/MemberView";
import {OgoneService} from "../../../Services/Supplier/OgoneService";
import {OgonePropertiesDTO} from "../../../Data/OgonePropertiesDTO";
import {RouteUtils} from "../../../utils/RouteUtils";
import {useTemplateUpdate} from "../../../Shared/Components/SidebarUpdateContextType/SidebarUpdateContextType";
import {DateUtils} from "../../../utils/DateUtils";
import RequestUtil from "../../../utils/RequestUtil";
import {InvoiceSearchRequestReducer} from "../../../Shared/Reducers/InvoiceSearchRequestReducer";
import {BbxDateRangePicker} from "../../../Shared/Components/BbxDateRangePicker/BbxDateRangePicker";
import {SupplierCreditNoteService} from "../../../Services/Supplier/SupplierCreditNoteService";
import {InvoiceConstants} from "./InvoiceConstants";


/**
 * Displays a table with all the invoices for the supplier. Possibility to download the invoices in pdf or csv format.
 * @return React.ReactElement
 */
export default function Invoices(): React.ReactElement {

    type CurrentActionType = 'downloadPdf' | 'downloadPdfNoDetails' | 'generateHtml' | null;

    const {t} = useTranslation();
    const {showToast} = useTemplateUpdate();
    const isLargeScreen = useMediaWidth(810);
    const [invoices, setInvoices] = useState<Page<InvoiceAcctDTO>>(new Page<InvoiceAcctDTO>());
    const [isLoading, setLoading] = useState<boolean>(true);
    const [state, dispatch] = useReducer(InvoiceSearchRequestReducer, new BbxRequest<InvoiceSearchRequestDTO>(InvoiceSearchRequestDTO));
    const [isOpenHtmlGeneratedModal, setIsOpenHtmlGeneratedModal] = useState(false);
    const [generatedHtml, setGeneratedHtml] = useState<string>("");
    const [currentAction, setCurrentAction] = useState<CurrentActionType>(null);
    const [member, setMember] = useState<MemberView>(new MemberView());
    const [ogoneProperties, setOgoneProperties] = useState<OgonePropertiesDTO>(new OgonePropertiesDTO());
    const [busyId, setBusyId] = useState<number>(0);
    const [isInvoice, setIsInvoice] = useState<boolean>(true);


    useEffect(() => {
        getInvoices(state);
        getUserData();
        getOgoneProperties();
    }, []);


    function getInvoices(invoiceSearchRequestDTOParam = new BbxRequest<InvoiceSearchRequestDTO>(InvoiceSearchRequestDTO)) {

        setInvoices({...invoices, isLoaded: false});

        SupplierInvoiceAcctService.getExpertInvoices(invoiceSearchRequestDTOParam).then((response) => {
            setInvoices(response);
            setLoading(false);
        });
    }


    const generateHtml = (id: number) => {

        if (id === 0) {
            showToast(BbxToastType.ERROR, t("Global error message"));
            return;
        }

        setBusyId(id);

        setCurrentAction('generateHtml');

        SupplierInvoiceAcctService.getInvoiceTemplateByUserIdAndInvoiceId(id).then(response => {
            setGeneratedHtml(response);
            setIsOpenHtmlGeneratedModal(true);
            setCurrentAction(null);
        }).catch((() => {
            setCurrentAction(null);
            showToast(BbxToastType.ERROR, t("Error while visualising invoice"));
        }))
    }

    const getOgoneProperties = () => {
        OgoneService.getOgoneProperties().then((response) => {
            setOgoneProperties(response);
        });
    }

    const getUserData = () => {
        SupplierRequestService.getSupplierData().then((response) => {
            setMember(response);
        }).catch(() => {
            showToast(BbxToastType.ERROR, t("Error while fetching user data"));
        });
    };

    const downloadPdf = (id: number, invoiceRef: string, details: boolean) => {

        if (id === 0) {
            showToast(BbxToastType.ERROR, t("Global error message"));
            return;
        }
        setBusyId(id)
        setCurrentAction(details ? 'downloadPdf' : 'downloadPdfNoDetails');

        SupplierInvoiceAcctService.downloadInvoicePdf(id, details).then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Invoice_' + invoiceRef + '-details.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setCurrentAction(null);
        }).catch((() => {
            setCurrentAction(null);
            showToast(BbxToastType.ERROR, t("Error while downloading PDF"));
        }))
    }


    const handleCloseModal = () => {
        setIsOpenHtmlGeneratedModal(false);
        setGeneratedHtml("");
        setIsInvoice(true);
    }

    function clearForm() {
        dispatch({type: 'SET_REQUEST', payload: RequestUtil.reset(state)});
        getInvoices(RequestUtil.reset(state));
    }

    const handlePageChange = (newPage: number) => {
        dispatch({type: 'SET_REQUEST', payload: RequestUtil.switchPage(state, newPage)});
        getInvoices(RequestUtil.switchPage(state, newPage));
    };


    const handleSearch = () => {
        dispatch({type: 'SET_REQUEST', payload: RequestUtil.newSearch(state)});
        getInvoices(RequestUtil.newSearch(state));
    }

    const generateCreditNoteHtml = (id: string) => {

        let idNumber = parseInt(id);

        if (idNumber === 0) {
            showToast(BbxToastType.ERROR, t("Global error message"));
            return;
        }

        SupplierCreditNoteService.getCreditNoteByUserIdAndInvoiceRef(idNumber).then(response => {

            if (!response) {
                showToast(BbxToastType.ERROR, t("Credit note doens't exist"));
                return;
            }

            SupplierCreditNoteService.getCreditNoteTemplate(response.id).then(response => {
                setGeneratedHtml(response);
                setIsOpenHtmlGeneratedModal(true);
                setIsInvoice(false);
            }).catch((() => {
                showToast(BbxToastType.ERROR, t("Error while visualising credit note"));
            }))
        }).catch(() => {
            showToast(BbxToastType.ERROR, t("Error while visualising credit note"));
        })

    }


    return (
        <>
            <div>
                {isLargeScreen && <AccountTabs/>}
                {/*can't be replaced by BbxSection*/}
                <div className={"bobex-card bg-white p-3"} style={{borderTop: "3px solid var(--bobex-blue)"}}>
                    <div className="d-flex flex-wrap justify-content-between">
                        <h2>{t("My invoices")}</h2>
                    </div>
                    <div className={"search-form"}>
                        <CRow>
                            <CCol md={6} className={" mb-2"}>
                                <CFormLabel>
                                    <span>
                                        {t("Invoice number")}
                                    </span>
                                    <CFormInput
                                        type="text"
                                        placeholder={t("Invoice number")}
                                        value={state.searchForm.invoiceRef ?? ""}
                                        onChange={(e) => {
                                            dispatch({type: 'SET_INVOICE_REF', payload: e.target.value});
                                        }}
                                    />

                                </CFormLabel>
                            </CCol>
                            <CCol md={6} className={" mb-2"}>
                                <CFormLabel>
                                    <span>
                                        {t("Date")}
                                    </span>
                                    <BbxDateRangePicker
                                        values={{
                                            dateStart: state.searchForm.dateStart,
                                            dateEnd: state.searchForm.dateEnd
                                        }}
                                        onDateEndChange={(date) => {
                                            dispatch({type: 'SET_DATE_END', payload: date});
                                        }}
                                        onDateStartChange={(date) => {
                                            dispatch({type: 'SET_DATE_START', payload: date});
                                        }}
                                    />
                                </CFormLabel>
                            </CCol>

                            <CCol md={6} className={"mb-2 d-flex justify-content-center align-item-end"}>
                                <CRow className="w-100">
                                    <CCol md={6}>
                                        <CButton color="info"
                                                 className="w-100"
                                                 onClick={handleSearch}>{t("Search")}</CButton>
                                    </CCol>
                                    <CCol md={6}>
                                        <CButton color="secondary"
                                                 className="w-100"
                                                 onClick={clearForm}>{t("Clear all")}</CButton>
                                    </CCol>
                                </CRow>

                            </CCol>
                        </CRow>
                    </div>

                    <hr/>

                    {isLoading ? <Loader/> :
                        <div>

                            <BbxTable page={invoices} request={state}
                                      columns={[{label: t("Invoice n°"), path: "invoiceReference"},
                                          {
                                              label: t("Invoice date"), function: (invoice: InvoiceAcctDTO) => {
                                                  return DateUtils.getDateFormatByContext(invoice.invoiceDate)
                                              }
                                          },
                                          {
                                              label: t("Amount"), className:"text-end", function: (invoice: InvoiceAcctDTO) => {
                                                  return (
                                                      <>
                                                          {
                                                              invoice.status === InvoiceConstants.PAYMENTS_PARTIAL ?
                                                                  <NumericFormat
                                                                      value={invoice.vatExempted ? invoice.montantPaymentPartial : VatUtil.VATCalculate(invoice.montantPaymentPartial)}
                                                                      displayType={'text'} thousandSeparator={'.'}
                                                                      decimalSeparator={','} prefix={'€ '}
                                                                      decimalScale={2}
                                                                      fixedDecimalScale={true}
                                                                  />
                                                                  :
                                                                  <NumericFormat
                                                                      value={invoice.vatExempted ? invoice.amount : VatUtil.VATCalculate(invoice.amount)}
                                                                      displayType={'text'} thousandSeparator={'.'}
                                                                      decimalSeparator={','} prefix={'€ '}
                                                                      decimalScale={2}
                                                                      fixedDecimalScale={true}
                                                                  />
                                                          }
                                                      </>
                                                  )
                                              }
                                          },
                                          {
                                              label: t("Status"), function:
                                                  (invoice: InvoiceAcctDTO) => {
                                                      return (
                                                          <>
                                                              {
                                                                  invoice.status === InvoiceConstants.INVOICE_SENT || invoice.status === InvoiceConstants.PAYMENTS_PARTIAL || invoice.status === InvoiceConstants.PAYMENTS_DUBIOUS || invoice.status === InvoiceConstants.PAYMENTS_LAWYER || invoice.status === InvoiceConstants.PAYMENTS_LAWYER_COURT ?
                                                                      <OgoneForm invoice={invoice}
                                                                                 user={member}
                                                                                 ogoneProperties={ogoneProperties}
                                                                                 t={t}
                                                                                 type={ogoneProperties.typeM}
                                                                      /> :
                                                                      <CButton size="sm" color="primary"
                                                                               variant="outline"
                                                                               disabled={true}>
                                                                          {invoice.status === InvoiceConstants.INVOICE_STATUS_PAID ? t("Paid") : t("Credited invoice")}
                                                                      </CButton>
                                                              }
                                                          </>
                                                      )
                                                  },
                                              isDisabled: RouteUtils.getContext() === 'ci'
                                          },
                                          {
                                              label: t("Download (PDF, Preview)"), function:
                                                  (invoice: InvoiceAcctDTO) => {
                                                      return (
                                                          <>
                                                              <div className="d-flex gap-5 justify-content-center">
                                                                  <div
                                                                      className={`icon-container ${currentAction && currentAction !== null ? "disabled" : ''}`}>
                                                                      {currentAction === 'downloadPdf' && busyId === invoice.id ?
                                                                          <CSpinner color="info"/> :
                                                                          <CTooltip content={t("Download as PDF file")}>
                                                                              <i className="fal fa-file-pdf file-icons"
                                                                                 onClick={() => {
                                                                                     if (!currentAction) {
                                                                                         setCurrentAction('downloadPdf');
                                                                                         downloadPdf(invoice.id, invoice.invoiceReference, true);
                                                                                     }
                                                                                 }}></i>
                                                                          </CTooltip>
                                                                      }
                                                                  </div>
                                                                  {/* <div
                                                                  className={`icon-container ${currentAction && currentAction !== null ? 'disabled' : ''}`}>
                                                                  {currentAction === 'downloadPdfNoDetails' && busyId === invoice.id ?
                                                                      <CSpinner color="info"/> :
                                                                      <CTooltip
                                                                          content={t("Download as PDF file without details")}>
                                                                          <div
                                                                              className="d-flex align-items-center gap-1">
                                                                              <i className="far fa-minus"/>
                                                                              <i className="fal fa-file-pdf file-icons"
                                                                                 onClick={() => {
                                                                                     if (!currentAction) {
                                                                                         setCurrentAction('downloadPdfNoDetails');
                                                                                         downloadPdf(invoice.id, invoice.invoiceReference, false);
                                                                                     }
                                                                                 }}></i>
                                                                          </div>
                                                                      </CTooltip>
                                                                  }
                                                              </div>*/}
                                                                  <div
                                                                      className={`icon-container ${currentAction && currentAction !== null ? 'disabled' : ''}`}>
                                                                      {currentAction === 'generateHtml' && busyId === invoice.id ?
                                                                          <CSpinner color="info"/> :
                                                                          <CTooltip content={t("View in HTML")}>
                                                                              <i className="fal fa-file-search file-icons"
                                                                                 onClick={() => {
                                                                                     if (!currentAction) {
                                                                                         setCurrentAction('generateHtml');
                                                                                         generateHtml(invoice.id);
                                                                                     }
                                                                                 }}></i>
                                                                          </CTooltip>
                                                                      }
                                                                  </div>
                                                              </div>
                                                          </>
                                                      )
                                                  }
                                          },
                                          {
                                              label: t("See linked credit note"),
                                              function: (invoice: InvoiceAcctDTO) => {

                                                  return (
                                                      <>
                                                          {invoice.status === InvoiceConstants.CREDIT_NOTE ?
                                                              <div className="d-flex gap-5 justify-content-center">
                                                                  <CTooltip content={t("View in HTML")}>
                                                                      <i className="fal fa-file-search file-icons"
                                                                         onClick={() => generateCreditNoteHtml(invoice.invoiceReference)}></i>
                                                                  </CTooltip>
                                                              </div>
                                                              :
                                                              null
                                                          }
                                                      </>
                                                  )
                                              }
                                          }
                                      ]}
                                      onPageChange={handlePageChange}
                                      sortByElements={[]}
                                      onSortByChange={() => {
                                      }}
                                      onSortDirectionChange={() => {
                                      }}
                                      title={""}
                                      mobileCardLayout={
                                          {
                                              header: {
                                                  start: (item: InvoiceAcctDTO) => {
                                                      return (
                                                          <>
                                                              {t("Invoice number")}: {item.invoiceReference}
                                                          </>
                                                      )
                                                  },
                                                  end: (item: InvoiceAcctDTO) => {
                                                      return (
                                                          <NumericFormat
                                                              value={item.vatExempted ? item.amount : VatUtil.VATCalculate(item.amount)}
                                                              displayType={'text'} thousandSeparator={'.'}
                                                              decimalSeparator={','} prefix={'€ '} decimalScale={2}
                                                              fixedDecimalScale={true}
                                                          />
                                                      )

                                                  },
                                                  className: (item: InvoiceAcctDTO) => {
                                                      return (item.status === InvoiceConstants.INVOICE_SENT || item.status === InvoiceConstants.PAYMENTS_PARTIAL || item.status === InvoiceConstants.PAYMENTS_DUBIOUS || item.status === InvoiceConstants.PAYMENTS_LAWYER || item.status === InvoiceConstants.PAYMENTS_LAWYER_COURT)  ? "invoiceToPayCardHeader" : "invoicePaidCardHeader"
                                                  }
                                              },
                                              body: (item: InvoiceAcctDTO) => {
                                                  return (
                                                      <div className="d-flex gap-5 justify-content-center">
                                                          {item.status === InvoiceConstants.INVOICE_SENT || item.status === InvoiceConstants.PAYMENTS_PARTIAL || item.status === InvoiceConstants.PAYMENTS_DUBIOUS || item.status === InvoiceConstants.PAYMENTS_LAWYER || item.status === InvoiceConstants.PAYMENTS_LAWYER_COURT ?
                                                              <OgoneForm invoice={item}
                                                                         user={member}
                                                                         ogoneProperties={ogoneProperties}
                                                                         t={t}
                                                                         type={ogoneProperties.typeM}
                                                              /> : null
                                                          }
                                                          <CTooltip content={t("Download as PDF file")}>
                                                              <div
                                                                  className={`icon-container ${currentAction && currentAction !== 'downloadPdf' ? 'disabled' : ''}`}>
                                                                  {currentAction === 'downloadPdf' && item.id === busyId ?
                                                                      <CSpinner color="info"/> :
                                                                      <i className="fal fa-file-pdf file-icons"
                                                                         onClick={() => {
                                                                             if (!currentAction) {
                                                                                 setCurrentAction('downloadPdf');
                                                                                 downloadPdf(item.id, item.invoiceReference, true);
                                                                             }
                                                                         }}></i>}
                                                              </div>
                                                          </CTooltip>
                                                          {/* <CTooltip content={t("Download as PDF file without details")}>
                                                              <div
                                                                  className={`icon-container ${currentAction && currentAction !== 'downloadPdfNoDetails' ? 'disabled' : ''}`}>
                                                                  {currentAction === 'downloadPdfNoDetails' && item.id === busyId ?
                                                                      <CSpinner color="info"/> :
                                                                      <div className="d-flex align-items-center gap-1">
                                                                          <i className="far fa-minus"/>
                                                                          <i className="fal fa-file-pdf file-icons"
                                                                             onClick={() => {
                                                                                 if (!currentAction) {
                                                                                     setCurrentAction('downloadPdfNoDetails');
                                                                                     downloadPdf(item.id, item.invoiceReference, false);
                                                                                 }
                                                                             }}></i>
                                                                      </div>}
                                                              </div>
                                                          </CTooltip>*/}
                                                          <CTooltip content={t("View in HTML")}>
                                                              <div
                                                                  className={`icon-container ${currentAction && currentAction !== 'generateHtml' ? 'disabled' : ''}`}>
                                                                  {currentAction === 'generateHtml' && item.id === busyId ?
                                                                      <CSpinner color="info"/> :
                                                                      <i className="fal fa-file-search file-icons"
                                                                         onClick={() => {
                                                                             if (!currentAction) {
                                                                                 setCurrentAction('generateHtml');
                                                                                 generateHtml(item.id);
                                                                             }
                                                                         }}></i>}
                                                              </div>
                                                          </CTooltip>
                                                      </div>
                                                  )
                                              }
                                          }}/>


                        </div>
                    }
                </div>

                <InvoicesGeneratedModal isOpen={isOpenHtmlGeneratedModal}
                                        handleCloseModal={handleCloseModal}
                                        generatedHtml={generatedHtml}
                                        isInvoice={isInvoice}
                                        t={t}
                />
            </div>
        </>
    )
}