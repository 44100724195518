import {LanguageView} from "../LanguageModel/LanguageView";
import {ExpertRegionZipsView} from "../ExpertRegionModel/ExpertRegionZipsView";

export class ExpertRoleView {

    id: number;
    priceSystem: number;
    dailyMaxLeads: number;
    monthlyMaxLeads: number;
    // expertRegions: ExpertRegionView[];
    languages: LanguageView[];

    endDateHolidaysCapping: Date;
    startDateHolidaysCapping: Date;


    constructor(data?: any) {
        this.id = data?.id ?? -1;
        this.priceSystem = data?.priceSystem ?? -1;
        this.dailyMaxLeads = data?.dailyMaxLeads ?? -1;
        this.monthlyMaxLeads = data?.monthlyMaxLeads ?? -1;
        // this.expertRegions = data?.expertRegions ?? [];
        this.languages = data?.languages ?? [];
        this.endDateHolidaysCapping = data?.endDateHolidaysCapping ?? null  ;
        this.startDateHolidaysCapping = data?.startDateHolidaysCapping ?? null;
    }

}