import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from 'react';
import {ExtranetFolderView} from "../../../Data/view/ExtranetFolderModel/ExtranetFolderView";
import {Page} from "../../../Data/Page";
import {useLocation} from "react-router-dom";
import {AccountManagerView} from "../../../Data/view/MemberModel/AccountManagerView";
import {BbxToastType} from "../../../CustomHooks/ToastCustomComponentProps/BbxToastType";
import {BbxToast} from "../../../CustomHooks/ToastCustomComponentProps/BbxToast";
import {ExpertRoleView} from "../../../Data/view/ExpertRoleModel/ExpertRoleView";
import {SupplierRequestService} from "../../../Services/Supplier/SupplierRequestService";
import {
    PartnerSubmissionCountPerYearMonthView
} from "../../../Data/view/PartnerSubmissionModel/PartnerSubmissionCountPerYearMonthView";
import {PartnerSubmissionRequestDTO} from "../../../Data/request/PartnerSubmissionRequestDTO";
import {DateUtils} from "../../../utils/DateUtils";
import {accountService} from "../../../Services/Helpers/AccountService";

type FolderUpdateProviderProps = {
    children: ReactNode;
};

interface SidebarUpdateContextType {
    refreshFolders: () => void;
    refreshHeader: () => void;
    triggerRefresh: boolean;
    triggerHeaderRefresh: boolean;
    folderView: Page<ExtranetFolderView>; // Assuming ExtranetFolderView is a type you have defined elsewhere
    setFolderView: Dispatch<SetStateAction<Page<ExtranetFolderView>>>; // Corrected type here
    isPartnerRfpEmpty: boolean;
    setIsPartnerRfpEmpty: Dispatch<SetStateAction<boolean>>;
    setAccountManager: Dispatch<SetStateAction<AccountManagerView>>;
    accountManager: AccountManagerView;
    showToast: (type: BbxToastType, message: string) => void;
    expertRole: ExpertRoleView;
    findCappingPerYearMonth: () => number;
}

// Provide initial context values matching the type above
const SidebarUpdateContext = createContext<SidebarUpdateContextType>({
    refreshFolders: () => {
    },
    refreshHeader: () => {

    },
    triggerRefresh: false,
    triggerHeaderRefresh: false,
    folderView: new Page<ExtranetFolderView>(),
    setFolderView: () => {
    },
    isPartnerRfpEmpty: true,
    setIsPartnerRfpEmpty: () => {
    },
    setAccountManager: () => {

    },
    accountManager: new AccountManagerView(),
    showToast: () => {
    },
    expertRole: new ExpertRoleView(),
    findCappingPerYearMonth: () => 0,
});

export const useTemplateUpdate = () => useContext(SidebarUpdateContext);

export const SidebarUpdateProvider: React.FC<FolderUpdateProviderProps> = ({children}) => {
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [triggerHeaderRefresh, setTriggerHeaderRefresh] = useState(false);
    const [folderView, setFolderView] = useState<Page<ExtranetFolderView>>(new Page<ExtranetFolderView>());
    const [isPartnerRfpEmpty, setIsPartnerRfpEmpty] = useState(false);
    const [accountManager, setAccountManager] = useState<AccountManagerView>(new AccountManagerView());
    const location = useLocation();
    const [showToastState, setShowToastState] = useState(false);
    const [toastType, setToastType] = useState(BbxToastType.SUCCESS);
    const [toastMessage, setToastMessage] = useState('');
    const [expertRole, setExpertRole] = useState<ExpertRoleView>(new ExpertRoleView());
    const [partnerSubmissionCountPerYearMonth, setPartnerSubmissionCountPerYearMonth] = useState<PartnerSubmissionCountPerYearMonthView[]>([]);
    const user = accountService.getUser();

    const isLoginPage = location.pathname === '/login';

    const getSupplier = () => {
        SupplierRequestService.getExpertRole().then((response) => {
            setExpertRole(response);
        })
    }

    function findCappingPerYearMonth() {
        const currentYearMonth = (DateUtils.getTodayDate().getFullYear() * 100 + DateUtils.getTodayDate().getMonth() + 1) + "";
        return partnerSubmissionCountPerYearMonth.find((yearMonth) => yearMonth.yearMonth === currentYearMonth)?.count ?? 0;
    }

    const getLeadsNumberPerYearMonth = () => {
        const requestArgs = new PartnerSubmissionRequestDTO();
        requestArgs.partnerRfpRequest.submissionDateStart = DateUtils.getTodayDate();
        requestArgs.partnerRfpRequest.submissionDateStart = new Date(2023, 1, 28, 0, 0, 0, 0);

        SupplierRequestService.getLeadNumberPerYearMonth(requestArgs).then((response) => {
            setPartnerSubmissionCountPerYearMonth(response);
        });
    }

    useEffect(() => {
        // Trigger the folder refresh function when the location changes
        refreshFolders();

        //To avoid error when the user is not logged in
        if (!isLoginPage) {
            if (user?.authority === 'supplier') {
                getSupplier();
                getLeadsNumberPerYearMonth();
            }
        }

    }, [location]); // This effect will run when the location changes

    useEffect(() => {
        setTriggerRefresh(false);
    }, []);

    const refreshFolders = () => {
        setTriggerRefresh(prev => !prev);
    };

    const refreshHeader = () => {
        setTriggerHeaderRefresh(prev => !prev);
    }

    const showToast = (type: BbxToastType, message: string) => {
        setToastType(type);
        setToastMessage(message);
        setShowToastState(true);
    };

    return (
        <SidebarUpdateContext.Provider
            value={{
                refreshFolders,
                refreshHeader,
                triggerRefresh,
                triggerHeaderRefresh,
                folderView,
                setFolderView,
                isPartnerRfpEmpty,
                setIsPartnerRfpEmpty,
                setAccountManager,
                accountManager,
                showToast,
                expertRole,
                findCappingPerYearMonth
            }}>
            {children}

            <BbxToast
                autohide={true}
                visible={showToastState}
                onClose={() => setShowToastState(false)}
                title={toastType === BbxToastType.SUCCESS ? 'Success' : 'Error'}
                body={toastMessage}
                type={toastType}
            />
        </SidebarUpdateContext.Provider>
    );
};
