import React, {Suspense} from 'react';

//React and Coreui Imports
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {icons} from './Assets/icons';

//Stylesheets
import 'bootstrap/dist/css/bootstrap.min.css';
import '@coreui/coreui-pro/dist/css/coreui.min.css';
import './App.css';

//Components
import Login from "./Components/Login/Login";
import SupplierApp from "./Components/Supplier/SupplierApp";
import BuyerApp from "./Components/Buyer/BuyerApp";
import Bo from "./Components/BO/Bo";
import ForgotPassword from "./Components/Supplier/Password/ForgotPassword";
import {CSpinner} from "@coreui/react";
import AffiliateApp from "./Components/Affiliate/AffiliateApp";
import LanguagePage from "./Shared/Components/LanguagePage/LanguagePage";
import {RouteUtils} from "./utils/RouteUtils";
import ThemeSelector from "./themes/ThemeSelector";
import {SidebarUpdateProvider} from "./Shared/Components/SidebarUpdateContextType/SidebarUpdateContextType";
import {LanguageUtils} from "./utils/LanguageUtils";


React.icons = icons;

/**
 * Main App component
 * @return {React.ReactElement}
 */
function App(): React.ReactElement {

    const context = RouteUtils.getContext();
    const language = LanguageUtils.getLanguage();

    let baseName;
    if (window.location.hostname.includes('localhost')) {
        baseName = '/extranet-' + RouteUtils.getContext();
    } else if (window.location.hostname.includes('web009')) {
        baseName = '/extranet-' + RouteUtils.getContext();
    } else {
        baseName = "";
    }

    const getManifestUrl = () => {
        return `/manifest-${context}-${language}.json`
    }

    return (
                <div className="bobex-app">
                    {
                        RouteUtils.getContext() == null
                            ? <Router basename={"/extranet"}>
                                <Suspense fallback={<CSpinner/>}>
                                    <Routes>
                                        <Route path="/" element={<LanguagePage/>}/>
                                    </Routes>
                                </Suspense>
                            </Router>
                            : <ThemeSelector>
                                <Router basename={baseName}>
                                    <Suspense fallback={<CSpinner/>}>
                                        <SidebarUpdateProvider>
                                            <Routes>
                                                <Route path="/" element={<Navigate to="/login" replace/>}/>
                                                <Route path="/login" element={<Login/>}/>
                                                <Route path="/bologin" element={<Bo/>}/>
                                                <Route path="/forgotpassword" element={<ForgotPassword/>}/>
                                                <Route path="/buyer/*" element={<BuyerApp/>}/>
                                                <Route path="/supplier/*" element={<SupplierApp/>}/>
                                                <Route path="/affiliate/*" element={<AffiliateApp/>}/>
                                            </Routes>
                                        </SidebarUpdateProvider>
                                    </Suspense>
                                </Router>
                            </ThemeSelector>
                    }
                </div>
    );
}

export default App;