//React and Coreui Imports
import CIcon from '@coreui/icons-react';

//Types
import {NavGroup} from '../../../Shared/Interfaces/Navigation.interface';

/**
 * Const Nav
 * Creates an array of objects that are used to create the sidebar navigation.
 * @NavGroup is an interface that defines the properties of the toggler (main categories in sidebar).
 * @NavItem is an interface that defines the properties of the navigation links (subcategories in sidebar).
 */
const affiliateNav: NavGroup[] = [
    {
        name: 'Statistics',
        items: [
            {
                name: 'My dashboard',
                icon: CIcon,
                iconProps: {
                    icon: 'cilSpeedometer',
                    className: 'affiliateNav-icon me-2',
                },
                to: '/affiliate/dashboard',
            },
            {
                name: 'My statistics',
                icon: CIcon,
                iconProps: {
                    icon: 'cilChartLine',
                    className: 'affiliateNav-icon me-2',
                },
                to: '/affiliate/statistics',
            }
        ]
    },
    {
        name: 'Marketing',
        items: [
            {
                name: 'Mailing kits',
                icon: CIcon,
                iconProps: {
                    icon: 'cilWallet',
                    className: 'affiliateNav-icon me-2'
                },
                to: '/affiliate/marketing/mailing',
            },
            {
                name: 'api-documentation',
                icon: CIcon,
                iconProps: {
                    icon: 'cilCode',
                    className: 'affiliateNav-icon me-2'
                },
                to: '/affiliate/marketing/api-documentation',
            },
            {
                name: 'Banners',
                icon: CIcon,
                iconProps: {
                    icon: 'cilInbox',
                    className: 'affiliateNav-icon me-2'
                },
                to: '/affiliate/marketing/banners',
            },
            {
                name: 'Landing pages',
                icon: CIcon,
                iconProps: {
                    icon: 'cilFindInPage',
                    className: 'affiliateNav-icon me-2'
                },
                to: '/affiliate/marketing/landing-pages',
            }
        ]
    },
    {
        name: 'Leads',
        items: [
            {
                name: 'My leads',
                icon: CIcon,
                iconProps: {
                    icon: 'cilAddressBook',
                    className: 'affiliateNav-icon me-2'
                },
                to: '/affiliate/leads/my-leads',
            },
            {
                name: 'Leads spam',
                icon: CIcon,
                iconProps: {
                    icon: 'cilReportSlash',
                    className: 'affiliateNav-icon me-2'
                },
                to: '/affiliate/leads/spam',
            }
        ]
    },
    {
        name: 'Invoices',
        items: [
            {
                name: 'Prices per lead',
                icon: CIcon,
                iconProps: {
                    icon: 'cilEuro',
                    className: 'affiliateNav-icon me-2'
                },
                to: '/affiliate/invoices/prices-per-leads',
            },
            {
                name: 'Billing report',
                icon: CIcon,
                iconProps: {
                    icon: 'cilMoney',
                    className: 'affiliateNav-icon me-2'
                },
                to: 'invoices/billing-report',
            },
            {
                name: 'My invoices',
                icon: CIcon,
                iconProps: {
                    icon: 'cilCreditCard',
                    className: 'affiliateNav-icon me-2'
                },
                teamMemberAllowed:false,
                to: '/affiliate/invoices/my-invoices',
            }
        ]
    },
    {
        name: 'Account',
        items: [
            {
                name: 'Manage account',
                icon: CIcon,
                iconProps: {
                    icon: 'cilLockLocked',
                    className: 'affiliateNav-icon me-2'
                },
                to: '/affiliate/account/manage-account',
            },
            {
                name: 'Team member',
                icon: CIcon,
                iconProps: {
                    icon: 'cilPeople',
                    className: 'affiliateNav-icon me-2'
                },
                teamMemberAllowed:false,
                to: '/affiliate/account/team-members',
            }
        ]
    }
];

export default affiliateNav;
